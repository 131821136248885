import { useRef, useEffect, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { ethers } from 'ethers'
import { AppShell, Container, Text, Anchor, Space, Title } from '@mantine/core'

import {
  metaMaskOnConnect,
  metaMaskOnChainChanged,
  metaMaskOnAccountsChanged,
  metaMaskOnDisconnect,
  fetchMaticBalance
} from './stores/metamask-slice'
import { metaMaskUtils } from './utils/metamask-utils'
import { CONTRACT_ADDRESS, CONTRACT_ABI } from './utils/contract-utils'
import { AppHeader } from './components/Layout/AppHeader'
import { PleaseConnect } from './components/PleaseConnect'
import { MintTokens } from './components/MintTokens'

import baloneyCritterImg from './assets/baloney-critter.png'

export function App() {
  const contractRef = useRef(null)
  const { metaMaskIsInstalled, currentAccount, chainIsPolygon } = useSelector(
    state => state.metaMask
  )
  const dispatch = useDispatch()

  // Handle MetaMask connect event
  const dispatchMetaMaskOnConnect = useCallback(
    connectInfo => {
      dispatch(metaMaskOnConnect())
    },
    [dispatch]
  )

  // Handle MetaMask chainChanged event
  const dispatchMetaMaskOnChainChanged = useCallback(
    chainId => {
      dispatch(metaMaskOnChainChanged())
    },
    [dispatch]
  )

  // Handle MetaMask accountsChanged event // returns accounts[]
  const dispatchMetaMaskOnAccountsChanged = useCallback(async () => {
    const accounts = await metaMaskUtils.requestAccounts()
    dispatch(metaMaskOnAccountsChanged({ accounts }))
  }, [dispatch])

  // Handle MetaMask disconnect event
  const dispatchMetaMaskOnDisconnect = useCallback(() => {
    dispatch(metaMaskOnDisconnect())
  }, [dispatch])

  // Attach/detach listeners for MetaMask events
  useEffect(() => {
    if (metaMaskIsInstalled) {
      window.ethereum.on('connect', dispatchMetaMaskOnConnect) // reload page
      window.ethereum.on('chainChanged', dispatchMetaMaskOnChainChanged) // reload page
      // returns accounts []
      window.ethereum.on('accountsChanged', dispatchMetaMaskOnAccountsChanged)
      window.ethereum.on('disconnect', dispatchMetaMaskOnDisconnect) // reload page

      return () => {
        window.ethereum.removeListener('connect', dispatchMetaMaskOnConnect)
        window.ethereum.removeListener(
          'chainChanged',
          dispatchMetaMaskOnChainChanged
        )
        window.ethereum.removeListener(
          'accountsChanged',
          dispatchMetaMaskOnAccountsChanged
        )
        window.ethereum.removeListener(
          'disconnect',
          dispatchMetaMaskOnDisconnect
        )
      }
    }
  }, [
    metaMaskIsInstalled,
    dispatchMetaMaskOnConnect,
    dispatchMetaMaskOnChainChanged,
    dispatchMetaMaskOnAccountsChanged,
    dispatchMetaMaskOnDisconnect
  ])

  // Fetch MATIC balance of current account
  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchMaticBalance(currentAccount))
    }
  }, [currentAccount, dispatch])

  // Initialize signed ethers contract
  useEffect(() => {
    if (chainIsPolygon) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()

      contractRef.current = new ethers.Contract(
        CONTRACT_ADDRESS,
        CONTRACT_ABI,
        signer
      )
    }
  }, [chainIsPolygon])

  return (
    <AppShell header={<AppHeader />} padding={'md'}>
      <Container>
        <Space h={40} />
        <Title align={'center'} order={1}>
          BaloneyCritters NFT
        </Title>
        <Space h={40} />
        <Text align={'center'}>
          BaloneyCritters is the funkiest new NFT project on Polygon!
        </Text>
        <Space h={10} />
        <Text align={'center'}>
          Browse collection on{' '}
          <Anchor
            href={`https://opensea.io/collection/baloneycritters`}
            target={'_blank'}>
            OpenSea
          </Anchor>
          ! View contract on{' '}
          <Anchor
            href={`https://polygonscan.com/address/${CONTRACT_ADDRESS}#code`}
            target={'_blank'}>
            Polygonscan
          </Anchor>
          .
        </Text>
        {(!currentAccount || !chainIsPolygon) && <PleaseConnect />}
        <Space h={30} />
        <MintTokens contractRef={contractRef} />
        <Space h={20} />
        <img src={baloneyCritterImg} style={{ width: 350, opacity: '0.4' }} />
      </Container>
    </AppShell>
  )
}
