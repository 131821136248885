import { useState, useCallback } from 'react'

import { useSelector } from 'react-redux'
import { ethers } from 'ethers'
import { showNotification } from '@mantine/notifications'
import { Group, Button } from '@mantine/core'

export function MintTokens(props) {
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const { contractRef } = props
  const { currentAccount, chainIsPolygon } = useSelector(
    state => state.metaMask
  )

  // Mint token
  const handleMintButtonClick = useCallback(async () => {
    if (contractRef.current && currentAccount) {
      setButtonDisabled(true)

      try {
        const res = await contractRef.current.mint({
          value: ethers.utils.parseEther('10')
        })
        res.wait(1)
        showNotification({
          title: 'Success!',
          message: `You've minted a Baloney Critter!`,
          color: 'green',
          autoClose: 7000
        })
        setButtonDisabled(false)
      } catch (error) {
        console.error(error)
        showNotification({
          title: 'Whoops!',
          message: 'Something went wrong...',
          color: 'red',
          autoClose: 7000
        })
        setButtonDisabled(false)
      }
    }
  }, [contractRef, currentAccount])

  return (
    <Group position={'center'}>
      <Button
        variant="gradient"
        gradient={{ from: 'teal', to: 'lime' }}
        disabled={buttonDisabled || !currentAccount || !chainIsPolygon}
        size={'lg'}
        onClick={() => handleMintButtonClick()}
        style={{ marginBottom: '6px' }}>
        Mint Critter (10 MATIC)
      </Button>
    </Group>
  )
}
