import { useSelector } from 'react-redux'
import { Text } from '@mantine/core'

export function UserBalances() {
  const { maticBalance } = useSelector(state => state.metaMask)

  return (
    <Text color={'dimmed'}>
      You have: {maticBalance ? maticBalance : '0'} $MATIC
    </Text>
  )
}
